import httpUtil from '../utils/httpUtil'
import {get} from "@/utils/request";

/**短视频管理列表 */

export const queryPage = params => httpUtil.post("/shortVideo/queryPage", params);

/**添加视频 */
export const add = params => httpUtil.post("/shortVideo/add", params);

/**删除视频 */

export const deleteById = params => httpUtil.post("/shortVideo/deleteById", params);
/**上架视频 */
export const updateData = params => httpUtil.post("/shortVideo/updateData", params);

/**商品列表 */
export const getGoodsInfoPC = params => get("/goods/goodList", params);

/**编辑 */
export const update = params => httpUtil.post("/shortVideo/update", params);
